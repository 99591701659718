$body: #eee;
$primary-color: #f44336;
$accent-color: #0277bd;
$accent-color-light: #64b5f6;
$black: #000000;
$dark-grey:#424242;
$grey:#616161;
$drawer-link: $dark-grey;
$footer-link: #ffffff;
$footer-link-hover: #eee;
$footer-color: $accent-color;
$drawer-active-link-background: #ffffff;
$promo-text: #000000;
$selected-collection: #eeeeee;
$selected-collection-text: $black;
$green: #009688;
$purple: #ba68c8;
$playlist-primary-color: $body;
$playlist-accent-color: $accent-color;
$facebook-color: #365899;
$soundcloud-color: #FF5500;
$contact-input-color: $primary-color;

.clickable {
  cursor: pointer;
}

.justify {
  text-align: justify;
}

.logo {
  width: 110px;
  margin-top: 10px;
}

@media only screen and (max-width: 420px) {
  .hide-on-small-only-title {
    display: none !important;
  }
}

body {
  background-color: $body;
}

@media only screen and (min-width: 992px) {

  nav > div > a.brand-logo {
    padding-left: 20px;
  }
}

@media only screen and (min-width: 601px) {
  nav > div > ul.side-nav > li > a.menu-header.subheader {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

nav > div > a.brand-logo, div > div > ul.side-nav > li > a.menu-header.subheader {
  font-size: 1.5rem;
  font-family: 'Product Sans', Roboto, sans-serif;
}

ul.side-nav > nav > div.nav-wrapper > a.brand-logo.nav-title.left {
  font-size: 1.5rem;
  font-family: 'Product Sans', Roboto, sans-serif;
  padding-left: 20px;
}

div > ul.side-nav > li > a.active, div > ul.side-nav > li > a.active > i {
  background: transparent !important;
}

nav > div > a.brand-logo.nav-title {
  font-family: Roboto, sans-serif;
}

nav .nav-title {
  padding: 0;
}

nav {
  background-color: $primary-color;
}

nav ul li a.active {
  background-color: $accent-color;
  font-weight: 500 !important;
}

nav ul.side-nav li a {
  color: $drawer-link;
}

div > ul.side-nav > li > a.active, div > ul.side-nav > li > a.active > i {
  background-color: $drawer-active-link-background;
  color: $accent-color;
}

.side-nav li > a {
  padding-right: 12px;
  padding-left: 32px;
}

.page-footer {
  background-color: $footer-color;
}

.footer-link {
  color: $footer-link;
}

.footer-link:hover {
  color: $footer-link-hover;
}

.card .card-action a:not(.btn):not(.btn-large):not(.btn-large):not(.btn-floating) {
  color: $accent-color;
}

.card .card-action a:not(.btn):not(.btn-large):not(.btn-large):not(.btn-floating):hover {
  color: $accent-color-light;
}

.card .card-image img {
  max-height: 800px;
  object-fit: cover;
}

.parallax-container {
  min-height: 380px;
  line-height: 0;
  height: auto;
  color: rgba(255, 255, 255, .9);
}

.parallax-container .section {
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .parallax-container .section {
    position: absolute;
    top: 40%;
  }

  #index-banner .section {
    top: 10%;
  }
}

.promo i {
  margin: 40px 0;
  color: $primary-color;
  font-size: 7rem;
  display: block;
}

.promo-caption {
  font-size: 1.7rem;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 0;
}

p.promo-caption, p.light.center {
  color: $promo-text;
}

.card > .card-content > .card-title {
  color: $accent-color;
  font-weight: 500;
}

.progress {
  background-color: #424242;
  margin: 0;
}

.progress:hover {
  margin: 0;
  cursor: pointer;
}

.progress .determinate {
  background-color: $primary-color;
}

.btn-player {
  padding: 0px 10px 0px 10px;
  color: #424242;
  height: auto;
}

.btn-player > i {
  font-size: 30px !important;
}

.btn-player:hover {
  color: $primary-color;
  background-color: transparent;
}

.btn-close {
  z-index: 1000;
}

.player-cover {
  width: 66px;
  padding: 9px;
}

.player-title-artist {
  line-height: 1.5rem;
  padding: 0px 16px 0px 0px;
  margin-top: 12px;
}

.player-artist {
  color: #757575;
  font-size: 16px;
  /*font-size: 1.1rem;*/
  font-weight: 400;
  text-transform: none;
}

.player-title {
  color: #000;
  /*font-size: 1.2rem;*/
  font-size: 18px;
  font-weight: 500;
  text-transform: none;
}

.copain {
  color: $black;
}

.artist, .slogan {
  color: $grey;
}

.timer {
  color: #424242;
  font-size: 14px;
  font-weight: 500;
}

.timer:hover {
  background-color: transparent;
}

.nav-player {
  position: fixed;
  left: 0px;
  bottom: 0px;
  height: 70px;
  width: 100%;
  background: #999;
  z-index: 9999;
  /*box-shadow:  0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);*/
  line-height: 64px;
}

/* IE 6 */
* html .nav-player {
  position: absolute;
  top: expression((0-(footer.offsetHeight)+(document.documentElement.clientHeight ? document.documentElement.clientHeight : document.body.clientHeight)+(ignoreMe = document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop))+'px');
}

.modal .modal-content {
  padding: 0;
}

.modal.bottom-sheet {
  background-color: $playlist-primary-color;
  max-height: 100%;
}

#closePlayer {
  color: $body;
}

.clickable {
  cursor: pointer;
}

.modal-cross {
  padding-right: 20px;
}

.collection .collection-item.active, .collection .collection-item.active>div>p.artist {
  background-color: $selected-collection !important;
  color: $selected-collection-text;
  font-weight: 600;
}

.song-icon {
  background-color: #fff !important;
  color: $playlist-accent-color !important;
  font-size: 2.8rem !important;
}

.song-icon a > div > i:hover {
  background-color: #CFD8DC !important;
}

.song-card {
  cursor: pointer;
}

.song-card:hover {
  background-color: $selected-collection;
}

.song-card > a:hover {
  color: $body;
}

input[type=range] {
  width: auto;
  border: 1px solid #CFD8DC;
}

input[type=range] + .thumb {
  background-color: $primary-color !important;
}

input[type=range]::-webkit-slider-thumb {
  background-color: $primary-color !important;
}

input[type=range]::-moz-range-thumb {
  background-color: $primary-color !important;
}

input[type=range]::-ms-thumb {
  background-color: $primary-color !important;
}

input[type=range]::-webkit-slider-runnable-track {
  background-color: $primary-color !important;
}

.input-field label.active {
  color: $primary-color;
}

.facebook-button {
  background-color: $facebook-color;
}

.soundcloud-button {
  background-color: $soundcloud-color;
}

.content-title {
  color: $accent-color;
  font-weight: 600;
  font-size: 2rem;
}

.contact-icon {
  color: $primary-color;
}

.contact-coord {
  padding-top: 24px;
}

@media only screen and (max-width: 1199px) {
  .soundcloud-button, .facebook-button {
    min-width: 191px;
  }
}

@media only screen and (min-width: 1200px) {
  .soundcloud-button, .facebook-button {
    min-width: 200px;
  }
}

.facebook-button:hover, .soundcloud-button:hover, .facebook-button:active, .soundcloud-button:active {
  color: $footer-link !important;
  background-color: $primary-color;
}

.social-buttons {
  padding-top: 16px;
}

.input-field input[type=text]:focus {
  border-bottom: 1px solid $contact-input-color !important;
  box-shadow: 0 1px 0 0 $contact-input-color !important;
}

.input-field textarea[class=materialize-textarea]:focus {
  border-bottom: 1px solid $contact-input-color !important;
  box-shadow: 0 1px 0 0 $contact-input-color !important;
}

.contact-submit {
  background-color: $primary-color;
}

.contact-submit:hover {
  background-color: $accent-color;
}

@media only screen and (min-width: 601px) {
  .contact-first-column {
    margin-bottom: -5px;
  }
}

@media only screen and (max-width: 601px) {
  .contact-first-column {
    margin-bottom: -8px;
  }
}

.valign-center {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
}

@font-face {
  font-family: 'perfectly-together';
  src: url('./fonts/perfectly-together/perfectly_together-webfont.woff2') format('woff2'),
  url('./fonts/perfectly-together/perfectly_together-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'dulcelin';
  src: url('./fonts/dulcelin/dulcelin-webfont.woff2') format('woff2'),
  url('./fonts/dulcelin/dulcelin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.definition {
  font-family: dulcelin, "Roboto", sans-serif;
  //font-size: 1.8rem;
}

.parallax-title {
  //font-family: perfectly-together, "Roboto", sans-serif;
}
